import "core-js/modules/es.array.push.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-409ff068"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "margin-bottom": "20px"
  }
};
const _hoisted_2 = {
  class: "content title"
};
const _hoisted_3 = {
  class: "left"
};
const _hoisted_4 = {
  key: 0,
  class: "danguan"
};
const _hoisted_5 = {
  class: "content"
};
const _hoisted_6 = {
  class: "numSpan"
};
const _hoisted_7 = {
  class: "content"
};
const _hoisted_8 = {
  class: "numSpan"
};
const _hoisted_9 = {
  class: "content"
};
const _hoisted_10 = {
  class: "numSpan"
};
const _hoisted_11 = {
  class: "left"
};
const _hoisted_12 = {
  class: "content"
};
import { ref } from 'vue';
import { showNotify } from '@nutui/nutui';
import { TriangleUp, TriangleDown } from '@nutui/icons-vue';
import axios from 'axios';
import { count } from './calculation.js';
import RateLineView from './RateLineView.vue';
import HRateLineView from './HRateLineView.vue';
export default {
  __name: 'AnalyzeHeatView',
  setup(__props, {
    expose: __expose
  }) {
    const timeNotify = msg => {
      showNotify.warn(msg, {
        duration: 2000
      });
    };
    const rateLineValue = ref([]);
    const rateRef = ref(null);
    const hrateRef = ref(null);
    const title = ref('');
    const winTables = ref([]);
    const loseTbales = ref([]);
    const isHeatVisible = ref(false);
    const heatList = ref([]);
    const loading = ref(false);
    const goalLine = ref("");
    // 显示弹出框
    const show = matchId => {
      isHeatVisible.value = true;
      winTables.value = [];
      loseTbales.value = [];
      heatList.value = [];
      rateLineValue.value = [];
      getFixedBonusV1(matchId);
    };
    const getFixedBonusV1 = async matchId => {
      loading.value = true;
      try {
        const response = await axios.get('https://webapi.sporttery.cn/gateway/jc/football/getFixedBonusV1.qry?clientCode=3001&matchId=' + matchId);
        let data = response.data;
        if (data.success) {
          let responseApi = null;
          try {
            responseApi = await axios.get('https://tuhaojie.asia/api-foot/supportRateV1/getRate?matchId=' + matchId);
          } catch {}
          let oddsHistory = data.value.oddsHistory;
          let hhad = oddsHistory.hhadList.length == 0 ? null : oddsHistory.hhadList[oddsHistory.hhadList.length - 1];
          let goalLineValue = hhad.goalLine.indexOf('+') != -1 ? parseInt(hhad.goalLine.split('+')[1]) : parseInt(hhad.goalLine);
          if (responseApi != null) {
            rateRef.value.pushMatch(responseApi.data, goalLineValue);
            hrateRef.value.pushMatch(responseApi.data);
          }
          title.value = oddsHistory.homeTeamAbbName + ' VS ' + oddsHistory.awayTeamAbbName;
          let beiJingRate = null;
          try {
            beiJingRate = await axios.get('https://tuhaojie.asia/api-foot/beijing/getBeiJin?matchId=' + oddsHistory.matchId);
          } catch {}
          goalLine.value = oddsHistory.hhadList[0].goalLine;
          if (data.value.sectionsNo999 != '') {
            title.value = oddsHistory.homeTeamAbbName + ' ' + data.value.sectionsNo999 + ' ' + oddsHistory.awayTeamAbbName;
          }
          const responseRate = await axios.get('https://webapi.sporttery.cn/gateway/jc/common/getSupportRateV1.qry?matchIds=' + oddsHistory.matchId + '&poolCode=hhad,had&sportType=1');
          const dataRate = responseRate.data;
          const value = dataRate.value;
          let changeList = [];
          let had = oddsHistory.hadList.length == 0 ? null : oddsHistory.hadList[oddsHistory.hadList.length - 1];
          const isEmpty = oddsHistory.hadList.length == 0;
          let hadRate = isEmpty ? {} : value['_' + oddsHistory.matchId].HAD;
          let hhadRate = value['_' + oddsHistory.matchId].HHAD;
          let winY = 0;
          let drawY = 0;
          let loseY = 0;
          let hadBettingSingle = 0;
          let hhadBettingSingle = 0;
          oddsHistory.singleList.forEach(item1 => {
            if (item1.poolCode == 'HAD') {
              hadBettingSingle = item1.single;
            } else if (item1.poolCode == 'HHAD') {
              hhadBettingSingle = item1.single;
            }
          });
          if (!isEmpty) {
            let num1 = parseInt(hadRate.hSupportRate.replace('%', '')) - parseInt(hadRate.hProbability.replace('%', ''));
            let num2 = parseInt(hadRate.dSupportRate.replace('%', '')) - parseInt(hadRate.dProbability.replace('%', ''));
            let num3 = parseInt(hadRate.aSupportRate.replace('%', '')) - parseInt(hadRate.aProbability.replace('%', ''));
            changeList.push({
              name: "支持量比例",
              value1: hadRate.hSupportRate,
              value2: hadRate.dSupportRate,
              value3: hadRate.aSupportRate,
              col: 1,
              type: 2,
              num1: num1,
              num2: num2,
              num3: num3
            });
            changeList.push({
              name: "竞猜赔率",
              value1: had.h,
              value2: had.d,
              value3: had.a,
              col: 1,
              type: hadBettingSingle == 0 ? 0 : 3
            });
            changeList.push({
              name: "竞猜赔率胜率",
              value1: hadRate.hProbability,
              value2: hadRate.dProbability,
              value3: hadRate.aProbability,
              col: 1,
              type: 0
            });
            changeList.push({
              name: "支持量",
              value1: hadRate.win,
              value2: hadRate.draw,
              value3: hadRate.lose,
              col: 1,
              type: 0
            });
            const hadTotal = hadRate.win + hadRate.draw + hadRate.lose;
            let winM = count(hadRate.win, had.h, '*');
            let drawM = count(hadRate.draw, had.d, '*');
            let loseM = count(hadRate.lose, had.a, '*');
            winY = count(hadTotal, winM, '-');
            drawY = count(hadTotal, drawM, '-');
            loseY = count(hadTotal, loseM, '-');
            changeList.push({
              name: "总支持量",
              value1: hadTotal,
              value2: hadTotal,
              value3: hadTotal,
              col: 3,
              type: 0
            });
            changeList.push({
              name: "支持量赔付",
              value1: winM,
              value2: drawM,
              value3: loseM,
              col: 1,
              type: 0
            });
            changeList.push({
              name: "竞猜赔付风险",
              value1: winY,
              value2: drawY,
              value3: loseY,
              col: 1,
              type: 1
            });
            //sumRate(had.h, had.d, had.a, hadRate.win, hadRate.draw, hadRate.lose);
          }
          let hnum1 = parseInt(hhadRate.hSupportRate.replace('%', '')) - parseInt(hhadRate.hProbability.replace('%', ''));
          let hnum2 = parseInt(hhadRate.dSupportRate.replace('%', '')) - parseInt(hhadRate.dProbability.replace('%', ''));
          let hnum3 = parseInt(hhadRate.aSupportRate.replace('%', '')) - parseInt(hhadRate.aProbability.replace('%', ''));
          changeList.push({
            name: hhad.goalLine + "支持量比",
            value1: hhadRate.hSupportRate,
            value2: hhadRate.dSupportRate,
            value3: hhadRate.aSupportRate,
            col: 1,
            type: 2,
            num1: hnum1,
            num2: hnum2,
            num3: hnum3
          });
          changeList.push({
            name: "竞猜赔率",
            value1: hhad.h,
            value2: hhad.d,
            value3: hhad.a,
            col: 1,
            type: hhadBettingSingle == 0 ? 0 : 3
          });
          changeList.push({
            name: "竞猜赔率胜率",
            value1: hhadRate.hProbability,
            value2: hhadRate.dProbability,
            value3: hhadRate.aProbability,
            col: 1,
            type: 2
          });
          changeList.push({
            name: "支持量",
            value1: hhadRate.win,
            value2: hhadRate.draw,
            value3: hhadRate.lose,
            col: 1,
            type: 0
          });
          const hhadTotal = hhadRate.win + hhadRate.draw + hhadRate.lose;
          let hwinM = count(hhadRate.win, hhad.h, '*');
          let hdrawM = count(hhadRate.draw, hhad.d, '*');
          let hloseM = count(hhadRate.lose, hhad.a, '*');
          let hwinY = count(hhadTotal, hwinM, '-');
          let hdrawY = count(hhadTotal, hdrawM, '-');
          let hloseY = count(hhadTotal, hloseM, '-');
          changeList.push({
            name: "总支持量",
            value1: hhadTotal,
            value2: hhadTotal,
            value3: hhadTotal,
            col: 3,
            type: 0
          });
          changeList.push({
            name: "支持量赔付",
            value1: hwinM,
            value2: hdrawM,
            value3: hloseM,
            col: 1,
            type: 0
          });
          changeList.push({
            name: "竞猜赔付风险",
            value1: hwinY,
            value2: hdrawY,
            value3: hloseY,
            col: 1,
            type: 1
          });
          if (beiJingRate != null && beiJingRate.data.handicap != null) {
            changeList.push({
              name: "北单赔率(" + beiJingRate.data.handicap + ")",
              value1: beiJingRate.data.sp1,
              value2: beiJingRate.data.sp2,
              value3: beiJingRate.data.sp3,
              col: 1,
              type: 0
            });
            changeList.push({
              name: "北单概率(" + beiJingRate.data.handicap + ")",
              value1: beiJingRate.data.sp1Rate + "%",
              value2: beiJingRate.data.sp2Rate + "%",
              value3: beiJingRate.data.sp3Rate + "%",
              col: 1,
              type: 0
            });
          }
          //sumRate(hhad.h, hhad.d, hhad.a, hhadRate.win, hhadRate.draw, hhadRate.lose);
          heatList.value = changeList;
          let winTable = [];
          let loseTbale = [];
          if (!isEmpty) {
            let goalLineValue = hhad.goalLine.indexOf('+') != -1 ? parseInt(hhad.goalLine.split('+')[1]) : parseInt(hhad.goalLine);
            if (goalLineValue > 0) {
              if (winY + hwinY > 0) {
                winTable.push('胜+让胜:' + count(winY, hwinY, '+'));
              } else if (winY + hwinY < 0) {
                loseTbale.push('胜+让胜:' + count(winY, hwinY, '+'));
              }
              if (drawY + hwinY > 0) {
                winTable.push('平+让胜:' + count(drawY, hwinY, '+'));
              } else if (drawY + hwinY < 0) {
                loseTbale.push('平+让胜:' + count(drawY, hwinY, '+'));
              }
              if (loseY + hdrawY > 0) {
                winTable.push('负+让平:' + count(loseY, hdrawY, '+'));
              } else if (loseY + hdrawY < 0) {
                loseTbale.push('负+让平:' + count(loseY, hdrawY, '+'));
              }
              if (loseY + hloseY > 0) {
                winTable.push('负+让负:' + count(loseY, hloseY, '+'));
              } else if (loseY + hloseY < 0) {
                loseTbale.push('负+让负:' + count(loseY, hloseY, '+'));
              }
              if (goalLineValue >= 2) {
                if (loseY + hwinY > 0) {
                  winTable.push('负+让胜:' + count(loseY, hwinY, '+'));
                } else if (loseY + hwinY < 0) {
                  loseTbale.push('负+让胜:' + count(loseY, hwinY, '+'));
                }
              }
            } else {
              if (winY + hwinY > 0) {
                winTable.push('胜+让胜:' + count(winY, hwinY, '+'));
              } else if (winY + hwinY < 0) {
                loseTbale.push('胜+让胜:' + count(winY, hwinY, '+'));
              }
              if (winY + hdrawY > 0) {
                winTable.push('胜+让平:' + count(winY, hdrawY, '+'));
              } else if (winY + hdrawY < 0) {
                loseTbale.push('胜+让平:' + count(winY, hdrawY, '+'));
              }
              if (drawY + hloseY > 0) {
                winTable.push('平+让负:' + count(drawY, hloseY, '+'));
              } else if (drawY + hloseY < 0) {
                loseTbale.push('平+让负:' + count(drawY, hloseY, '+'));
              }
              if (loseY + hloseY > 0) {
                winTable.push('负+让负:' + count(loseY, hloseY, '+'));
              } else if (loseY + hloseY < 0) {
                loseTbale.push('负+让负:' + count(loseY, hloseY, '+'));
              }
              if (goalLineValue <= -2) {
                if (winY + hloseY > 0) {
                  winTable.push('胜+让负:' + count(winY, hloseY, '+'));
                } else if (winY + hloseY < 0) {
                  loseTbale.push('胜+让负:' + count(winY, hloseY, '+'));
                }
              }
            }
          } else {
            if (hwinY > 0) {
              winTable.push('让胜:' + hwinY);
            } else if (hwinY < 0) {
              loseTbale.push('让胜:' + hwinY);
            }
            if (hdrawY > 0) {
              winTable.push('让平:' + hdrawY);
            } else if (hdrawY < 0) {
              loseTbale.push('让平:' + hdrawY);
            }
            if (hloseY > 0) {
              winTable.push('让负:' + hloseY);
            } else if (hloseY < 0) {
              loseTbale.push('让负:' + hloseY);
            }
          }
          winTables.value = winTable;
          loseTbales.value = loseTbale;
        } else {
          timeNotify(data.errorMessage);
        }
        loading.value = false;
      } catch (error) {
        // 处理错误
        console.error(error);
        loading.value = false;
      }
    };
    // 关闭弹出框
    const close = () => {
      isHeatVisible.value = false;
    };
    const sumRate = (h, d, a, x, y, z) => {
      //返还率
      let rate = count(1, count(count(1, h, '/'), count(count(1, d, '/'), count(1, a, '/'), "+"), '+'), '/');
      let hRate = count(rate, h, "/");
      let dRate = count(rate, d, "/");
      let aRate = count(rate, a, "/");
      let sumRate1 = count(count(count(x, h, '*'), count(x, count(y, z, "+"), "+"), "-"), hRate, "*");
      let sumRate2 = count(count(count(y, d, '*'), count(x, count(y, z, "+"), "+"), "-"), dRate, "*");
      let sumRate3 = count(count(count(z, a, '*'), count(x, count(y, z, "+"), "+"), "-"), aRate, "*");
      console.log(count(sumRate1, count(sumRate2, sumRate3, "+"), "+"));
    };

    // 暴露给外部使用的函数
    __expose({
      show,
      close
    });
    return (_ctx, _cache) => {
      const _component_nut_col = _resolveComponent("nut-col");
      const _component_nut_row = _resolveComponent("nut-row");
      const _component_var_chip = _resolveComponent("var-chip");
      const _component_var_col = _resolveComponent("var-col");
      const _component_var_row = _resolveComponent("var-row");
      const _component_var_collapse_item = _resolveComponent("var-collapse-item");
      const _component_var_collapse = _resolveComponent("var-collapse");
      const _component_var_loading = _resolveComponent("var-loading");
      const _component_nut_popup = _resolveComponent("nut-popup");
      return _openBlock(), _createBlock(_component_nut_popup, {
        visible: isHeatVisible.value,
        "onUpdate:visible": _cache[1] || (_cache[1] = $event => isHeatVisible.value = $event),
        closeable: "",
        style: {
          padding: '50px 10px',
          height: '85%',
          width: '90%',
          overflow: 'auto'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_var_loading, {
          description: "加载中",
          loading: loading.value
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_nut_row, null, {
            default: _withCtx(() => [_createVNode(_component_nut_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_2, "(" + _toDisplayString(goalLine.value) + ")" + _toDisplayString(title.value), 1)]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_var_row, {
            gutter: [10, 10]
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(winTables.value, (winTable, indexWin) => {
              return _openBlock(), _createBlock(_component_var_col, {
                key: indexWin,
                span: 12
              }, {
                default: _withCtx(() => [_createVNode(_component_var_chip, {
                  type: "danger",
                  block: ""
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(winTable), 1)]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024);
            }), 128)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(loseTbales.value, (loseTbale, indexLose) => {
              return _openBlock(), _createBlock(_component_var_col, {
                key: indexLose,
                span: 12
              }, {
                default: _withCtx(() => [_createVNode(_component_var_chip, {
                  type: "primary",
                  block: ""
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(loseTbale), 1)]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024);
            }), 128))]),
            _: 1
          })]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(heatList.value, (change, indexChange) => {
            return _openBlock(), _createBlock(_component_nut_row, {
              key: indexChange
            }, {
              default: _withCtx(() => [change.col == 1 ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 0
              }, [_createVNode(_component_nut_col, {
                span: 6
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [change.type == 3 ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(change.name), 1)) : (_openBlock(), _createElementBlock(_Fragment, {
                  key: 1
                }, [_createTextVNode(_toDisplayString(change.name), 1)], 64))])]),
                _: 2
              }, 1024), _createVNode(_component_nut_col, {
                span: 6
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createElementVNode("span", _hoisted_6, _toDisplayString(change.value1), 1), change.type == 1 && change.value1 > 0 ? (_openBlock(), _createBlock(_unref(TriangleUp), {
                  key: 0,
                  color: "rgb(250, 44, 25)",
                  class: "icon"
                })) : change.type == 1 && change.value1 < 0 ? (_openBlock(), _createBlock(_unref(TriangleDown), {
                  key: 1,
                  color: "rgb(100, 181, 120)",
                  class: "icon"
                })) : change.type == 2 && change.num1 <= -10 ? (_openBlock(), _createBlock(_unref(TriangleDown), {
                  key: 2,
                  color: "rgb(128 206 245)",
                  class: "icon"
                })) : change.type == 2 && change.num1 <= -20 ? (_openBlock(), _createBlock(_unref(TriangleDown), {
                  key: 3,
                  color: "rgb(11 26 219)",
                  class: "icon"
                })) : change.type == 2 && change.num1 >= 10 ? (_openBlock(), _createBlock(_unref(TriangleUp), {
                  key: 4,
                  color: "rgb(243 134 122)",
                  class: "icon"
                })) : change.type == 2 && change.num1 >= 20 ? (_openBlock(), _createBlock(_unref(TriangleUp), {
                  key: 5,
                  color: "rgb(243 26 2)",
                  class: "icon"
                })) : _createCommentVNode("", true)])]),
                _: 2
              }, 1024), _createVNode(_component_nut_col, {
                span: 6
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_7, [_createElementVNode("span", _hoisted_8, _toDisplayString(change.value2), 1), change.type == 1 && change.value2 > 0 ? (_openBlock(), _createBlock(_unref(TriangleUp), {
                  key: 0,
                  color: "rgb(250, 44, 25)",
                  class: "icon"
                })) : change.type == 1 && change.value2 < 0 ? (_openBlock(), _createBlock(_unref(TriangleDown), {
                  key: 1,
                  color: "rgb(100, 181, 120)",
                  class: "icon"
                })) : change.type == 2 && change.num2 <= -10 ? (_openBlock(), _createBlock(_unref(TriangleDown), {
                  key: 2,
                  color: "rgb(128 206 245)",
                  class: "icon"
                })) : change.type == 2 && change.num2 <= -20 ? (_openBlock(), _createBlock(_unref(TriangleDown), {
                  key: 3,
                  color: "rgb(11 26 219)",
                  class: "icon"
                })) : change.type == 2 && change.num2 >= 10 ? (_openBlock(), _createBlock(_unref(TriangleUp), {
                  key: 4,
                  color: "rgb(243 134 122)",
                  class: "icon"
                })) : change.type == 2 && change.num2 >= 20 ? (_openBlock(), _createBlock(_unref(TriangleUp), {
                  key: 5,
                  color: "rgb(243 26 2)",
                  class: "icon"
                })) : _createCommentVNode("", true)])]),
                _: 2
              }, 1024), _createVNode(_component_nut_col, {
                span: 6
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_9, [_createElementVNode("span", _hoisted_10, _toDisplayString(change.value3), 1), change.type == 1 && change.value3 > 0 ? (_openBlock(), _createBlock(_unref(TriangleUp), {
                  key: 0,
                  color: "rgb(250, 44, 25)",
                  class: "icon"
                })) : change.type == 1 && change.value3 < 0 ? (_openBlock(), _createBlock(_unref(TriangleDown), {
                  key: 1,
                  color: "rgb(100, 181, 120)",
                  class: "icon"
                })) : change.type == 2 && change.num3 <= -10 ? (_openBlock(), _createBlock(_unref(TriangleDown), {
                  key: 2,
                  color: "rgb(128 206 245)",
                  class: "icon"
                })) : change.type == 2 && change.num3 <= -20 ? (_openBlock(), _createBlock(_unref(TriangleDown), {
                  key: 3,
                  color: "rgb(11 26 219)",
                  class: "icon"
                })) : change.type == 2 && change.num3 >= 10 ? (_openBlock(), _createBlock(_unref(TriangleUp), {
                  key: 4,
                  color: "rgb(243 134 122)",
                  class: "icon"
                })) : change.type == 2 && change.num3 >= 20 ? (_openBlock(), _createBlock(_unref(TriangleUp), {
                  key: 5,
                  color: "rgb(243 26 2)",
                  class: "icon"
                })) : _createCommentVNode("", true)])]),
                _: 2
              }, 1024)], 64)) : change.col == 3 ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 1
              }, [_createVNode(_component_nut_col, {
                span: 6
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_11, _toDisplayString(change.name), 1)]),
                _: 2
              }, 1024), _createVNode(_component_nut_col, {
                span: 18
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_12, _toDisplayString(change.value1), 1)]),
                _: 2
              }, 1024)], 64)) : _createCommentVNode("", true)]),
              _: 2
            }, 1024);
          }), 128)), _createVNode(_component_var_collapse, {
            modelValue: rateLineValue.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => rateLineValue.value = $event)
          }, {
            default: _withCtx(() => [_createVNode(_component_var_collapse_item, {
              title: "胜平负支持量走势",
              name: "1"
            }, {
              default: _withCtx(() => [_createVNode(RateLineView, {
                ref_key: "rateRef",
                ref: rateRef
              }, null, 512)]),
              _: 1
            }), _createVNode(_component_var_collapse_item, {
              title: "让胜平负支持量走势",
              name: "2"
            }, {
              default: _withCtx(() => [_createVNode(HRateLineView, {
                ref_key: "hrateRef",
                ref: hrateRef
              }, null, 512)]),
              _: 1
            })]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }, 8, ["loading"])]),
        _: 1
      }, 8, ["visible"]);
    };
  }
};