import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from 'vue';
import * as echarts from 'echarts';
import { useStore } from 'vuex';
export default {
  __name: 'HRateLineView',
  setup(__props, {
    expose: __expose
  }) {
    const store = useStore();
    const phonenumber = ref(store.state.user.phonenumber);
    const chartContainer = ref(null);
    const chartContainer2 = ref(null);
    const chartContainer3 = ref(null);
    const rateList = ref([{}]);
    const flag = ref(true);
    const pushMatch = list => {
      rateList.value = list;
      var yAxis1 = {
        type: 'value'
      };
      var yAxis2 = {
        type: 'value'
      };
      var yAxis3 = {
        type: 'value'
      };
      if (phonenumber.value == '15705840617' || flag.value) {
        let value1 = [];
        let value2 = [];
        let value3 = [];
        for (var i = 0; i < list.length; i++) {
          value1.push(Number(list[i].hhadHRate.replace('%', '')));
          value1.push(Number(list[i].hhsupportRate.replace('%', '')));
          value2.push(Number(list[i].hhadDRate.replace('%', '')));
          value2.push(Number(list[i].dhsupportRate.replace('%', '')));
          value3.push(Number(list[i].hhadARate.replace('%', '')));
          value3.push(Number(list[i].ahsupportRate.replace('%', '')));
        }
        let maxValue1 = Math.ceil(Math.max(...value1) * 1.1);
        let minValue1 = Math.floor(Math.min(...value1) * 0.9);
        let maxValue2 = Math.ceil(Math.max(...value2) * 1.1);
        let minValue2 = Math.floor(Math.min(...value2) * 0.9);
        let maxValue3 = Math.ceil(Math.max(...value3) * 1.1);
        let minValue3 = Math.floor(Math.min(...value3) * 0.9);
        let data1 = Math.ceil((maxValue1 - minValue1) / 3);
        let data2 = Math.ceil((maxValue2 - minValue2) / 3);
        let data3 = Math.ceil((maxValue3 - minValue3) / 3);
        let max = Math.max(...[data1, data2, data3]);
        let maxValue = max * 3;
        let yAxis = {
          type: 'value',
          interval: 3,
          scale: true,
          axisLabel: {
            show: true
          },
          splitLine: {
            show: true
          },
          axisTick: {
            show: false //不显示刻度
          },
          axisLine: {
            show: false
          },
          nameTextStyle: {
            color: "#FFFFFF"
          },
          splitArea: {
            show: false
          }
        };
        yAxis1 = JSON.parse(JSON.stringify(yAxis));
        // yAxis1.min = minValue1
        // yAxis1.max = minValue1 + maxValue
        yAxis2 = JSON.parse(JSON.stringify(yAxis));
        // yAxis2.min = minValue2
        // yAxis2.max = minValue2 + maxValue
        yAxis3 = JSON.parse(JSON.stringify(yAxis));
        // yAxis3.min = minValue3
        // yAxis3.max = minValue3 + maxValue
      }
      footBallRate(list, yAxis1);
      footBallRate2(list, yAxis2);
      footBallRate3(list, yAxis3);
    };
    const footBallRate = async (list, yAxis) => {
      try {
        let title = [];
        let value1 = [];
        let value2 = [];
        for (var i = 0; i < list.length; i++) {
          title.push(list[i].dateString);
          value1.push(Number(list[i].hhadHRate.replace('%', '')));
          list[i].value = Number(list[i].hhadHRate.replace('%', ''));
          value2.push(Number(list[i].hhsupportRate.replace('%', '')));
        }
        const chart = echarts.init(chartContainer.value);
        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false,
              axisPointer: {
                lineStyle: {
                  type: 'solid',
                  color: '#b2b5c1'
                }
              }
            },
            textStyle: {
              fontSize: '.24rem'
            },
            hideDelay: 0,
            padding: 5,
            formatter: function (params) {
              var data = params[0].data;
              var time = data.dateString;
              var total = data.hhmoney + data.dhmoney + data.ahmoney;
              var html = `${time}<br>让胜：${data.hhadH} <br>让平：${data.hhadD}<br>让负：${data.hhadA} <br>总量：${total} <br>让胜量：${data.hhmoney} <br>让胜支持率：${data.hhsupportRate} <br>`;
              return html;
            }.bind(this)
          },
          xAxis: {
            type: 'category',
            data: title
          },
          legend: {
            data: ['让胜概率', '支持率']
          },
          yAxis: yAxis,
          grid: {
            left: '10%',
            right: '3%',
            bottom: '8%'
          },
          series: [{
            name: '让胜概率',
            data: list,
            type: 'line',
            smooth: true
          }, {
            name: '支持率',
            data: value2,
            type: 'line',
            smooth: true
          }]
        };
        chart.setOption(option);
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    };
    const footBallRate2 = async (list, yAxis) => {
      try {
        let title = [];
        let value1 = [];
        let value2 = [];
        for (var i = 0; i < list.length; i++) {
          title.push(list[i].dateString);
          value1.push(Number(list[i].hhadDRate.replace('%', '')));
          list[i].value = Number(list[i].hhadDRate.replace('%', ''));
          value2.push(Number(list[i].dhsupportRate.replace('%', '')));
        }
        const chart = echarts.init(chartContainer2.value);
        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false,
              axisPointer: {
                lineStyle: {
                  type: 'solid',
                  color: '#b2b5c1'
                }
              }
            },
            textStyle: {
              fontSize: '.24rem'
            },
            hideDelay: 0,
            padding: 5,
            formatter: function (params) {
              var data = params[0].data;
              var time = data.dateString;
              var total = data.hhmoney + data.dhmoney + data.ahmoney;
              var html = `${time}<br>让胜：${data.hhadH} <br>让平：${data.hhadD}<br>让负：${data.hhadA} <br>总量：${total} <br>让平量：${data.dhmoney} <br>让平支持率：${data.dhsupportRate} <br>`;
              return html;
            }.bind(this)
          },
          xAxis: {
            type: 'category',
            data: title
          },
          legend: {
            data: ['让平概率', '支持率']
          },
          yAxis: yAxis,
          grid: {
            left: '10%',
            right: '3%',
            bottom: '8%'
          },
          series: [{
            name: '让平概率',
            data: list,
            type: 'line',
            smooth: true
          }, {
            name: '支持率',
            data: value2,
            type: 'line',
            smooth: true
          }]
        };
        chart.setOption(option);
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    };
    const footBallRate3 = async (list, yAxis) => {
      try {
        let title = [];
        let value1 = [];
        let value2 = [];
        for (var i = 0; i < list.length; i++) {
          title.push(list[i].dateString);
          value1.push(Number(list[i].hhadARate.replace('%', '')));
          list[i].value = Number(list[i].hhadARate.replace('%', ''));
          value2.push(Number(list[i].ahsupportRate.replace('%', '')));
        }
        const chart = echarts.init(chartContainer3.value);
        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false,
              axisPointer: {
                lineStyle: {
                  type: 'solid',
                  color: '#b2b5c1'
                }
              }
            },
            textStyle: {
              fontSize: '.24rem'
            },
            hideDelay: 0,
            padding: 5,
            formatter: function (params) {
              var data = params[0].data;
              var time = data.dateString;
              var total = data.hhmoney + data.dhmoney + data.ahmoney;
              var html = `${time}<br>让胜：${data.hhadH} <br>让平：${data.hhadD}<br>让负：${data.hhadA} <br>总量：${total} <br>让负量：${data.ahmoney} <br>让负支持率：${data.ahsupportRate} <br>`;
              return html;
            }.bind(this)
          },
          xAxis: {
            type: 'category',
            data: title
          },
          legend: {
            data: ['让负概率', '支持率']
          },
          yAxis: yAxis,
          grid: {
            left: '10%',
            right: '3%',
            bottom: '8%'
          },
          series: [{
            name: '让负概率',
            data: list,
            type: 'line',
            smooth: true
          }, {
            name: '支持率',
            data: value2,
            type: 'line',
            smooth: true
          }]
        };
        chart.setOption(option);
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    };

    // 暴露给外部使用的函数
    __expose({
      pushMatch
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        ref_key: "chartContainer",
        ref: chartContainer,
        style: {
          "width": "90vw",
          "height": "300px"
        }
      }, null, 512), _createElementVNode("div", {
        ref_key: "chartContainer2",
        ref: chartContainer2,
        style: {
          "width": "90vw",
          "height": "300px"
        }
      }, null, 512), _createElementVNode("div", {
        ref_key: "chartContainer3",
        ref: chartContainer3,
        style: {
          "width": "90vw",
          "height": "300px"
        }
      }, null, 512)], 64);
    };
  }
};